import { objectsApi } from '@/api'
import eventBus from '@/eventBus.js'

const defultState = {
  units: [],
  searchedUnits: [],
  unitsGroups: [],
  searchedGroups: [],
  showUnitsGroup: [],
  lastSearchKey: '',
  lastTypeSearch: '',
  allUnits: [],
  recoveryId: null,
  ports: null,
  oldUnits: [],
  defaultUnits: [],
  filteredUnits: [],
  unitId: null,
  activeObject: null,
  searchedServices: [],
  activeObjectCouplings: [],
  activeObjectNewCouplings: [],
  activeObjectShifts: [],
  activeObjectNewShifts: [],
  activeObjectFields: [],
  modalType: '',
  errors: [],
  sensorTableError: false,
  objectsWrapperModal: {
    open: false,
    type: 'create'
  },
  fromMonitoring: false,
  immutableUnits: [],
  immutableGroups: [],
  objectAnnotationSwitch: true,
  isSubmit: false,
  invalidElements: [],
  activeElementScroll: null,
  currentTableSortType: null,
  selectedUnits: [],
  sensorCopyType: null
}

const getters = {
  getSensorCopyType(state) {
    return state.sensorCopyType
  },
  getSelectedUnits(state) {
    return state.selectedUnits
  },
  getCopySensors: state => {
    return state.activeObject
  },
  units: state => {
    return state.units
  },
  unitGroups: state => {
    return state.unitsGroups
  },
  allUnits: state => {
    return state.allUnits
  },
  defaultUnits: state => {
    return state.defaultUnits
  },
  unit: state => id => {
    return state.units.find(unit => unit.id === id)
  },
  unitGroup: state => id => {
    return state.unitsGroups.find(unitGroup => unitGroup.id === id)
  },

  unitsGroup: state => {
    return state.unitsGroups
  },

  getTerminalType: state => id => {
    return state.units.find(unit => unit.id === id).terminal_type.key
  },
  unitId: state => {
    return state.unitId
  },
  activeObject: state => state.activeObject,
  activeObjectCouplings: state => state.activeObjectCouplings,
  activeObjectNewCouplings: state => state.activeObjectNewCouplings,
  activeObjectShifts: state => state.activeObjectShifts,
  activeObjectNewShifts: state => state.activeObjectNewShifts,
  activeObjectFields: state => state.activeObjectFields,
  modalType: state => state.modalType,
  errors: state => state.errors,
  sensorTableError: state => state.sensorTableError,
  searchedUnits: state => state.searchedUnits,
  searchedGroups: state => state.searchedGroups,
  lastSearchKey: state => state.lastSearchKey,
  lastTypeSearch: state => state.lastTypeSearch,
  objectsWrapperModal: state => state.objectsWrapperModal,
  fromMonitoring: state => state.fromMonitoring,
  objectAnnotationSwitch: state => state.objectAnnotationSwitch,
  isSubmit: state => state.isSubmit,
  invalidElements: state => state.invalidElements,
  activeElementScroll: state => state.activeElementScroll,
  currentTableSortType: state => state.currentTableSortType
}

const actions = {
  updateSensors({ commit }, payload) {
    commit('UPDATE_SENSORS', payload)
  },
  postAllObjects({ state, commit }, payload) {
    return new Promise(resolve => {
      objectsApi.postAllObjects(payload).then(res => {
        resolve(res.list)
      })
    })
  },

  SET_FILTRED_UNITS({ commit }, filterData) {
    commit('SET_FILTRED_UNITS', filterData)
  },
  SHOW_UNITS_GROUP({ commit }, payload) {
    commit('GET_UNITS_GROUP', payload)
  },
  DEL_UNITS_GROUP({ commit }, payload) {
    commit('DELETE_UNITS_GROUP', payload)
  },
  HANDLE_KEYSEARCH({ commit }, key) {
    commit('SET_KEYSEARCH', key)
  },
  HANDLE_TYPESEARCH({ commit }, type) {
    commit('SET_TYPESEARCH', type)
  },
  GET_UNITS({ commit }) {
    return new Promise(resolve => {
      objectsApi.getObjects(
        response => {
          commit('SET_UNITS', response.data.list)
          commit('SET_ALLUNITS', response.data.list)
          commit('SET_IMMUTABLE_UNITS', response.data.list)
          resolve(response.data.list)
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  SEARCH_UNIT({ commit }, formData) {
    return new Promise(resolve => {
      objectsApi.searchObject(
        formData,
        response => {
          commit('SET_UNITS_SEARCHED', response.data.list)
          resolve(response.data.list)
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  SEARCH_UNITSENSOR({ commit }, formData) {
    return new Promise(resolve => {
      objectsApi.searchObject(
        formData,
        response => {
          commit('SET_UNITSENSOR', response.data.list)
          resolve(response.data.list)
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  SEARCH_UNIT_GROUP({ commit }, formData) {
    return new Promise(resolve => {
      objectsApi.searchObjectGroup(
        formData,
        response => {
          commit('SET_GROUP_UNITS', response.data.list)
          resolve(response.data.list)
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    })
  },
  WEBSOCKET_UNITS_CHANGE({ commit }, { id, operation }) {
    if (operation === 'DELETE') {
      commit('DELETE_UNITS', [id])
      commit('setUnitId', id)
    } else {
      objectsApi.getObject(
        id,
        response => {
          if (operation === 'POST') {
            commit('CREATE_UNIT', response.data)
          } else {
            commit('UPDATE_UNIT', response.data)
          }
        },
        error => {
          eventBus.$showError(error.response.data.message)
        }
      )
    }
  },
  WEBSOCKET_UNITSGROUP_CHANGE({ commit }, { id, operation }) {
    objectsApi.getGroupObjectId(
      id,
      response => {
        if (operation === 'POST') {
          commit('CREATE_GROUPUNIT', response.data)
        } else if (operation === 'DELETE') {
          commit('DELETE_GROUPUNITS', [id])
        } else {
          commit('UPDATE_UNITGROUP', response.data)
        }
      },
      error => {
        eventBus.$showError(error.response.data.message)
      }
    )
  },
  CREATE_UNIT({ commit }, formData) {
    return new Promise((resolve, reject) => {
      objectsApi.createObject(
        { formData },
        response => {
          commit('CREATE_UNIT', response.data)
          commit('SET_ACTIVE_OBJECT', response.data)

          resolve()
        },
        error => {
          console.log(error.response, 'error.responseerror.response')
          eventBus.$showError(error.response.data.message)

          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE_UNIT({ commit }, formData) {
    return new Promise((resolve, reject) => {
      objectsApi.updateObject(
        formData.id,
        { formData },
        response => {
          commit('UPDATE_UNIT', response.data)
          commit('UPDATE_SEARCHED_UNIT', response.data)
          commit('SET_ACTIVE_OBJECT', response.data)
          commit('COPY_SENSORS', response.data)
          resolve(response.data)
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  RECOVER_ITEMS({ commit }, data) {
    return new Promise((resolve, reject) => {
      objectsApi.recover(
        data.id,
        () => {
          commit('RECOVER', data)
          resolve()
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE_UNIT({ commit }, unitIds) {
    const promiseList = []

    promiseList.push(
      new Promise((resolve, reject) => {
        objectsApi.deleteObject(unitIds, resolve, reject)
      })
    )
    Promise.all(promiseList)
      .then(async res => {
        await commit('DELETE_UNITS', unitIds)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch(error => {
        eventBus.$showError(error.response.data.message)
      })
  },
  VALIDATE_UNIT({ commit }, { formData, activeTab }) {
    return new Promise((resolve, reject) => {
      objectsApi.validateObject(
        formData,
        () => {
          commit('SET_ERRORS', [])
          resolve()
        },
        error => {
          commit('SET_ERRORS', [activeTab])
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  GET_GROUP_UNITS({ commit }) {
    return new Promise((resolve, reject) => {
      objectsApi.getGroupObject(
        response => {
          commit('SET_GROUP_UNITS', response.data.list)
          resolve(response.data.list)
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error)
        }
      )
    })
  },
  CREATE_UNITGROUP({ commit }, formData) {
    return new Promise((resolve, reject) => {
      objectsApi.createUnitGroup(
        { formData },
        response => {
          commit('CREATE_GROUPUNIT', response.data)
          resolve(response)
        },
        error => {
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  UPDATE_UNITGROUP({ commit }, formData) {
    // console.log(formData, 'formData')
    return new Promise((resolve, reject) => {
      objectsApi.updateUnitGroup(
        formData.id,
        { formData },
        response => {
          commit('UPDATE_UNITGROUP', response.data)
          resolve()
        },
        error => {
          console.log(' error ', error)
          eventBus.$showError(error.response.data.message)
          reject(error.response.data.message)
        }
      )
    })
  },
  DELETE_GROUPUNIT({ commit }, groupunitsIds) {
    const promiseList = []
    promiseList.push(
      new Promise((resolve, reject) => {
        objectsApi.deleteUnitGroup(groupunitsIds, resolve, reject)
      })
    )
    return Promise.all(promiseList)
      .then(res => {
        commit('DELETE_GROUPUNITS', groupunitsIds)
        commit('SET_RECOVERYID', res[0].data)
      })
      .catch(error => {
        eventBus.$showError(error.response.data.message)
      })
  }
}

const mutations = {
  setSensorCopyType(state, sensorCopyType) {
    state.sensorCopyType = sensorCopyType
  },
  setSelectedUnits(state, payload) {
    state.selectedUnits = payload
  },
  COPY_SENSORS(state, formData) {
    state.activeObject = formData
  },
  UPDATE_SENSORS(state, payload) {
    state.activeObject.sensors = payload
  },
  SET_FILTRED_UNITS(state, filterData) {
    const filtredUnits = state.defaultUnits.filter(el => filterData.includes(el.id))
    state.units = state.allUnits = filtredUnits
  },
  SET_OLD_FILTRED_UNITS(state, units) {
    state.units = state.allUnits = units
  },
  RESET_SEARCH_UNITS(state) {
    state.allUnits = state.defaultUnits
    state.searchedUnits = []
    state.searchedGroups = []
  },
  RESET_FILTRED_UNITS(state) {
    state.filteredUnits = []

    state.units = state.allUnits = state.defaultUnits
  },
  SET_PORTS(state, ports) {
    state.ports = ports
  },
  SET_RECOVERYID(state, id) {
    state.recoveryId = id
  },
  RECOVER(state, units) {
    if (units.type === 'units') {
      units.itemsDeleted.forEach(unit => {
        state.allUnits.splice(unit.index, 0, unit)
        state.defaultUnits.splice(unit.index, 0, unit)
        state.units.splice(unit.index, 0, unit)
        state.allUnits = state.allUnits.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
        state.defaultUnits = state.defaultUnits.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
        state.units = state.units.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i)
        // state.units.push(unit)
      })
    } else if (units.type === 'unitsgroups') {
      units.itemsDeleted.forEach(unit => state.unitsGroups.splice(unit.index, 0, unit))
    }
    // units.type === 'units'
    //   ? units.itemsDeleted.forEach(unit => {
    //       state.allUnits.splice(unit.index, 0, unit)
    //       state.defaultUnits.splice(unit.index, 0, unit)
    //       state.units.push(unit)
    //     })
    //   : units.type === 'unitsgroups'
    //   ? units.itemsDeleted.forEach(unit =>
    //       state.unitsGroups.splice(unit.index, 0, unit)
    //     )
    //   : console.log('trailers', units)
  },
  CREATE_UNIT(state, unit) {
    if (!state.units.some(x => x.id === unit.id)) {
      state.units = state.defaultUnits = state.allUnits = [...state.units, unit]
    }
  },
  UPDATE_UNIT(state, unit) {
    const indexOfUnit = state.units.findIndex(elem => elem.id === unit.id)

    state.units = state.allUnits = state.defaultUnits = [...state.units.slice(0, indexOfUnit), unit, ...state.units.slice(indexOfUnit + 1)]

    const indexOfImmutableUnit = state.immutableUnits.findIndex(elem => elem.id === unit.id)

    state.immutableUnits = [...state.immutableUnits.slice(0, indexOfImmutableUnit), unit, ...state.immutableUnits.slice(indexOfImmutableUnit + 1)]
  },
  UPDATE_UNITGROUP(state, groupunit) {
    const indexOfGroupUnit = state.unitsGroups.findIndex(elem => elem.id === groupunit.id)
    state.unitsGroups = [...state.unitsGroups.slice(0, indexOfGroupUnit), groupunit, ...state.unitsGroups.slice(indexOfGroupUnit + 1)]

    const indexOfImmutableGroupUnit = state.immutableGroups.findIndex(elem => elem.id === groupunit.id)

    state.immutableGroups = [...state.immutableGroups.slice(0, indexOfImmutableGroupUnit), groupunit, ...state.immutableGroups.slice(indexOfImmutableGroupUnit + 1)]
  },
  CREATE_GROUPUNIT(state, groupunit) {
    if (!state.unitsGroups.some(x => x.id === groupunit.id)) {
      state.unitsGroups = [...state.unitsGroups, groupunit]
    }
  },
  SET_UNITS(state, units) {
    const indexedItems = units.map((item, index) => ({
      index,
      ...item
    }))
    state.units = indexedItems
    state.defaultUnits = indexedItems
  },
  SET_UNITS_SEARCHED(state, units) {
    const searchedUnits = state.defaultUnits.filter(unit => units.some(el => unit.id === el.id))

    state.allUnits = searchedUnits
    state.searchedUnits = searchedUnits
  },
  UPDATE_SEARCHED_UNIT(state, unit) {
    if (state.searchedUnits && state.searchedUnits.length) {
      const searchedUnits = state.searchedUnits.map(item => {
        if (item.id === unit.id) {
          item = { ...unit }
        }

        return item
      })

      state.allUnits = searchedUnits
      state.searchedUnits = searchedUnits
    }
  },
  SET_GROUPS_SEARCHED(state, units) {
    const searchedGroups = state.unitsGroups.filter(unit => units.some(el => unit.id === el.id))

    state.searchedGroups = searchedGroups
  },
  SET_SERVICES_SEARCHED(state, services) {
    const searchedServices = state.activeObject.services.filter(service => services.some(el => service.id === el.id))

    state.searchedServices = searchedServices
  },
  SET_UNITSENSOR(state, units) {
    const currentUnits = []
    state.units.forEach(unit => {
      units.forEach(element => {
        return element.id === unit.id ? currentUnits.push(unit) : ''
      })
    })
    if (currentUnits.length > 0) {
      state.allUnits = currentUnits
    } else {
      state.allUnits = []
    }
  },
  SET_ALLUNITS(state, units) {
    const indexedItems = units.map((item, index) => ({
      index,
      ...item
    }))
    state.allUnits = indexedItems
  },
  SET_KEYSEARCH(state, key) {
    state.lastSearchKey = key
  },
  GET_UNITS_GROUP(state, payload) {
    state.showUnitsGroup.push(payload)
  },
  DELETE_UNITS_GROUP(state) {
    const lasItem = [state.showUnitsGroup[state.showUnitsGroup.length - 1]]
    state.showUnitsGroup = lasItem
  },
  SET_TYPESEARCH(state, type) {
    state.lastTypeSearch = type
  },
  SET_OLDUNITS(state) {
    state.allUnits = state.units
  },
  DELETE_UNITS(state, unitsIds) {
    // state.units = state.units.filter(el => el.id !== (unitsIds[0].id || unitsIds[0]) && !unitsIds.includes(el.id))
    // state.defaultUnits = state.defaultUnits.filter(el => el.id !== (unitsIds[0].id || unitsIds[0]) && !unitsIds.includes(el.id))
    // state.allUnits = state.allUnits.filter(el => el.id !== (unitsIds[0].id || unitsIds[0]) && !unitsIds.includes(el.id))
    // state.unitGroups = state.unitsGroups.filter(el => el.id !== (unitsIds[0].id || unitsIds[0]) && !unitsIds.includes(el.id))

    // Если что-то сломается вернуть обратно
    // console.log('delete triggred from store after notif', unitsIds)
    state.units = state.units.filter(item => !unitsIds.includes(item.id))

    state.defaultUnits = state.defaultUnits.filter(item => !unitsIds.includes(item.id))
    state.allUnits = state.allUnits.filter(item => !unitsIds.includes(item.id))
    state.unitsGroups.forEach(group => {
      group.units = group.units.filter(unitGroup => !unitsIds.includes(unitGroup.id))
    })

    state.immutableUnits = state.immutableUnits.filter(item => !unitsIds.includes(item.id))
  },
  DELETE_GROUPUNITS(state, groupunitsIds) {
    state.unitsGroups = state.unitsGroups.filter(item => !groupunitsIds.includes(item.id))

    state.immutableGroups = state.immutableGroups.filter(item => !groupunitsIds.includes(item.id))
  },
  SET_GROUP_UNITS(state, unitsGroups) {
    const indexedItems = unitsGroups.map((item, index) => ({
      index,
      ...item
    }))
    state.unitsGroups = indexedItems
    state.immutableGroups = indexedItems.slice()
  },

  SORT_GROUPS(state, { sortValue, prop, sortType }) {
    if (sortValue === 'asc') {
      if (sortType && sortType === 'number') {
        const property = prop === 'objects' ? 'units' : 'users'
        state.unitsGroups = state.unitsGroups.sort((a, b) => {
          return a[property].length > b[property].length ? 1 : -1
        })
      } else {
        state.unitsGroups = state.unitsGroups.sort((a, b) => {
          if (a[prop] || b[prop]) {
            return a[prop].toLowerCase() < b[prop].toLowerCase() ? 1 : -1
          }
        })
      }
    } else if (sortValue === 'desc') {
      if (sortType && sortType === 'number') {
        const property = prop === 'objects' ? 'units' : 'users'
        state.unitsGroups = state.unitsGroups.sort((a, b) => {
          return a[property].length > b[property].length ? -1 : 1
        })
      } else {
        state.unitsGroups = state.unitsGroups.sort((a, b) => {
          if (a[prop] || b[prop]) {
            return a[prop].toLowerCase() < b[prop].toLowerCase() ? -1 : 1
          }
        })
      }
    } else {
      state.unitsGroups = state.immutableGroups.slice()
    }
  },
  setUnitId(state, payload) {
    state.unitId = payload
  },
  SET_ACTIVE_OBJECT(state, payload) {
    state.activeObject = payload
  },
  SET_SENSORS_ACTIVE_OBJECT(state, payload) {
    state.activeObject.sensors = payload
  },
  SET_SENSOR_CALIBRATION(state, { table, values, id }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx][table] = values
  },
  SET_SENSOR_PARAM_CALIBRATION(state, { table, values, id, param }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx][param][table] = values
  },
  SET_SENSOR_MAX_MIN_BORDER(state, { type, id, value }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx][`${type}_valid`] = value
  },
  SET_IS_TEXT_CONVERTING(state, { id, value }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx].is_string_value = value
  },
  SET_CUSTOM_FIELDS_ACTIVE_OBJECT(state, payload) {
    state.activeObject.custom_fields = payload
  },
  SET_SENSOR_CUSTOM_FIELDS(state, { id, values }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx].custom_fields = values
  },
  DELETE_SENSOR_CUSTOM_FIELD(state, { id, itemIdx }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx].custom_fields.splice(itemIdx, 1)
  },
  ADD_SENSOR_CUSTOM_FIELD(state, { id, item }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx].custom_fields.push(item)
  },
  CHANGE_SENSOR_CUSTOM_FIELD(state, { id, index, prop, value }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)

    state.activeObject.sensors[idx].custom_fields[index][prop] = value
  },
  SET_COUPLINGS_ACTIVE_OBJECT(state, payload) {
    state.activeObjectCouplings = payload
  },
  SET_NEW_COUPLINGS_ACTIVE_OBJECT(state, payload) {
    state.activeObjectNewCouplings = payload
  },
  SET_SHIFTS_ACTIVE_OBJECT(state, payload) {
    state.activeObjectShifts = payload
  },
  SET_NEW_SHIFTS_ACTIVE_OBJECT(state, payload) {
    state.activeObjectNewShifts = payload
  },
  SET_SERVICES_ACTIVE_OBJECT(state, payload) {
    state.activeObject.services = payload
  },
  SET_FIELDS_ACTIVE_OBJECT(state, payload) {
    state.activeObjectFields = payload
  },
  ADD_FIELD_ACTIVE_OBJECT(state, payload) {
    state.activeObject.custom_fields.push(payload)
  },
  DELETE_FIELD_ACTIVE_OBJECT(state, localId) {
    state.activeObjectFields = state.activeObjectFields.filter(item => item.localId !== localId)
    state.activeObject.custom_fields = state.activeObject.custom_fields.filter(item => item.localId !== localId)
  },
  SET_MODAL_TYPE(state, type) {
    state.modalType = type
  },
  SET_ERRORS(state, error) {
    state.errors = error
  },
  SET_SENSORS_ERRORS(state, error) {
    state.sensorTableError = error
  },
  SET_OBJECTS_MODAL(state, modal) {
    state.objectsWrapperModal = modal
  },
  SET_FROM_MONITORING(state, value) {
    state.fromMonitoring = value
  },

  SORT_UNITS(state, { sortValue, prop, sortType }) {
    if (sortValue === 'asc') {
      if (sortType && sortType === 'number') {
        state.units = state.units.sort((a, b) => {
          return a[prop] > b[prop] ? 1 : -1
        })
      } else if (prop && prop === 'protocol') {
        state.units = state.units.sort((a, b) => {
          return a.terminal_type.value.toLowerCase() < b.terminal_type.value.toLowerCase() ? 1 : -1
        })
      } else {
        state.units = state.units.sort((a, b) => {
          if (a[prop] || b[prop]) {
            return a[prop].toLowerCase() < b[prop].toLowerCase() ? 1 : -1
          }
        })
      }
    } else if (sortValue === 'desc') {
      if (sortType && sortType === 'number') {
        state.units = state.units.sort((a, b) => {
          return a[prop] > b[prop] ? -1 : 1
        })
      } else if (prop && prop === 'protocol') {
        state.units = state.units.sort((a, b) => {
          return a.terminal_type.value.toLowerCase() < b.terminal_type.value.toLowerCase() ? -1 : 1
        })
      } else {
        state.units = state.units.sort((a, b) => {
          if (a[prop] || b[prop]) {
            return a[prop].toLowerCase() < b[prop].toLowerCase() ? -1 : 1
          }
        })
      }
    } else {
      state.units = state.immutableUnits.slice()
    }
  },
  SET_IMMUTABLE_UNITS(state, value) {
    state.immutableUnits = value
  },
  SET_OBJECT_ANNOTATION_SWITCH(state, value) {
    state.objectAnnotationSwitch = value
  },
  ADD_CONNECTED_SENSOR(state, { sensorId, id }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === sensorId)

    state.activeObject.sensors[idx].connected_sensors.push({ id })
  },
  DELETE_CONNECTED_SENSOR(state, { sensorId, id }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === sensorId)

    state.activeObject.sensors[idx].connected_sensors = state.activeObject.sensors[idx].connected_sensors.filter(item => item.id !== id)
  },
  SET_FILTER_TYPE(state, { id, payload }) {
    const idx = state.activeObject.sensors.findIndex(item => item.id === id)
    state.activeObject.sensors[idx].filter_type = payload
  },
  SET_IS_SUBMIT(state, value) {
    state.isSubmit = value
  },
  SET_INVALID_ELEMENTS(state, value) {
    state.invalidElements = value
  },
  SET_ACTIVE_ELEMENT_SCROLL(state, value) {
    state.activeElementScroll = value
  },
  SET_CURRENT_TABLE_SORT_TYPE(state, value) {
    state.currentTableSortType = value
  }
}

export default {
  namespaced: true,
  state: defultState,
  mutations,
  actions,
  getters
}
